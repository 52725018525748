// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-activistes-guatemala-js": () => import("./../../../src/pages/activistes-guatemala.js" /* webpackChunkName: "component---src-pages-activistes-guatemala-js" */),
  "component---src-pages-activistes-hondures-js": () => import("./../../../src/pages/activistes-hondures.js" /* webpackChunkName: "component---src-pages-activistes-hondures-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticies-js": () => import("./../../../src/pages/noticies.js" /* webpackChunkName: "component---src-pages-noticies-js" */),
  "component---src-templates-activist-js": () => import("./../../../src/templates/activist.js" /* webpackChunkName: "component---src-templates-activist-js" */),
  "component---src-templates-country-js": () => import("./../../../src/templates/country.js" /* webpackChunkName: "component---src-templates-country-js" */),
  "component---src-templates-department-js": () => import("./../../../src/templates/department.js" /* webpackChunkName: "component---src-templates-department-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

